import axios from "../config"

class UserAccountService{
    axios;
    constructor(){
        this.axios = axios;
    }
    getUserTypes() {
      let url = `/a`;
      return this.axios.get(url).then(request => request.data);
    }
    getUserAccountsDetail() {
      let url = `/useraccounts/detail`
      return axios.get(url).then(request => request.data);
    }
}

const service = new UserAccountService();
export default service;