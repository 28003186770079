import Vue from "vue";
import Vuex from "vuex";
import VuexPersistance from "vuex-persist";
import axios from "../config";

Vue.use(Vuex);
const vuexLocal = new VuexPersistance({
  storage: window.localStorage
});
export default new Vuex.Store({
  state: {
    userAccount: {
      userName: "",
      password: "",
      role: "",
      profileName:"",
      domain:""
    },
    print:false,
  },
  plugins: [vuexLocal.plugin],
  mutations: {
    updateUserAccount: function(state, value) {
      state.userAccount = value;
      if (value.password != undefined) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `deli${value.password}`;
      } else {
        axios.defaults.headers.common["Authorization"] = `deli`;
      }
    },
    removeUserAccount: function(state) {
      state.userAccount = {};
    },
    updatePrint: function(state,value) {
      state.print = value;
    },
  },
  actions: {}
});
